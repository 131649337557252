import React from 'react';
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer"
import CoCurricular from '../components/CoCurricular';
import Topbar from '../components/Topbar';

const CoCurricularPage = () => {
    return (
        <Layout pageTitle="Shanti Asiatic School | InfraStructure">
            <Topbar />
            <NavOne />
            <PageHeader title="Co-curricular" />
            <CoCurricular />
            <Footer />
        </Layout>
    );
};

export default CoCurricularPage;
